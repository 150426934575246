import React, { useEffect, useRef } from 'react';
import { Paragraph, ParagraphTitle } from './paragraph';
import { gsap, Power4 } from 'gsap';

const imageContainer = {
  transform: 'translateY(35px)',
  opacity: 0,
  zIndex: -1,
  height: 250,
  width: '100%',
  flex: 1,
  position: 'relative',
  borderRadius: '35px 35px 0px 0px',
  overflow: 'hidden',
};

const overlay = {
  position: 'absolute',
  marginBottom: -1,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background:
    'linear-gradient(to top, rgba(19, 22, 18, 1) 10%, rgba(19, 22, 18, 0.1) 80%)',
};

const imageStyle = { height: '100%', width: '100%', objectFit: 'cover' };

const ANIMATION_DURATION = 1.7;

const ParagraphSection = ({ title, description, image, reverse, link }) => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 520;

  return isMobile ? (
    <MobileSection
      title={title}
      description={description}
      link={link}
      image={image}
    />
  ) : (
    <DesktopSection
      title={title}
      description={description}
      link={link}
      image={image}
      reverse={reverse}
    />
  );
};

//
//
// DESKTOP SECTION

const MobileSection = ({ title, description, link, image }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && imageRef.current) {
            gsap
              .to(imageRef.current, ANIMATION_DURATION, {
                opacity: 1.3,
                transform: 'translateY(0px)',
              })
              .delay(0.1);
          }
        });
      },
      { threshold: 0 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);
  return (
    <section
      className="section"
      style={{
        margin: 0,
        paddingTop: 150,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <div style={{ maxWidth: '100' }}>
        <div ref={imageRef} className="imageContainer" style={imageContainer}>
          <img
            style={imageStyle}
            src={image}
            alt={'Johanna Brun'}
            draggable="false"
          />
          <div style={overlay} />
        </div>
        <ParagraphTitle
          string={title}
          delay={0.2}
          fontSize={45}
          orientation={'center'}
        />
        <br />
        <br />
        <Paragraph
          string={description}
          delay={0.5}
          show
          link={link}
          orientation={'center'}
        />
      </div>
    </section>
  );
};

//
//
// DESKTOP SECTION

const DesktopSection = ({ title, description, link, image, reverse }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && imageRef.current) {
            gsap
              .to(imageRef.current, ANIMATION_DURATION, {
                opacity: 1,
                transform: 'translateY(0px)',
                ease: Power4.easeOut,
              })
              .delay(0.5);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);
  return (
    <section
      className="section"
      style={{
        margin: 0,
        paddingTop: 250,
        display: 'flex',
        width: '100%',
        flexDirection: reverse ? 'row-reverse' : 'row',
      }}
    >
      <div style={{ maxWidth: '40%' }}>
        <ParagraphTitle
          string={title}
          delay={0}
          fontSize={55}
          orientation={'left'}
        />
        <br />
        <br />
        <Paragraph
          string={description}
          delay={0.5}
          show
          link={link}
          orientation={'left'}
        />
      </div>
      <div
        ref={imageRef}
        className="imageContainer"
        style={{
          ...imageContainer,
          ...{
            marginLeft: !reverse && 70,
            marginRight: reverse && 70,
            height: 450,
            maxWidth: '50%',
          },
        }}
      >
        <img
          style={imageStyle}
          src={image}
          alt={'Johanna Brun'}
          draggable="false"
        />
        <div style={overlay} />
      </div>
    </section>
  );
};

export default ParagraphSection;
