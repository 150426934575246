import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import ImageSlider from '../components/image';
import { Title, Text, TextLink } from '../components/text';
import { ParagraphTitle, Paragraph } from '../components/paragraph';
import johanna from '../images/johanna_piano.jpg';
import johanna1 from '../images/johanna1.jpeg';
import johanna2 from '../images/johanna2.jpeg';
import johanna3 from '../images/johanna3.jpeg';
import johannaBrun1 from '../images/johanna_brun1.jpeg';
import johannaBrun2 from '../images/johanna_brun2.jpg';
import johannaBrun3 from '../images/johanna_brun3.jpeg';
import johannaBrun3Wide from '../images/johanna_brun3_wide.jpg';
import johannaBrun4 from '../images/johanna_brun4.jpg';
import johannaBrun5 from '../images/johanna_brun5.jpeg';
import johannaBrun51 from '../images/johanna_brun51.jpg';
import johannaBrun5Wide from '../images/johanna_brun5_wide.jpg';
import johannaBrun6 from '../images/johanna_brun6.jpg';
import johannaBrun6New from '../images/johanna_brun6_new.jpg';
import johannaBrun7 from '../images/johanna_brun7.jpg';
import johannaBrun8 from '../images/johanna_brun8.jpg';
import johannaBrun9 from '../images/johanna_brun9.jpeg';
import johannaCover from '../images/johanna_brun_cover.jpeg';
import prophecy from '../images/prophecy.jpg';
import favicon from '../favicon.png';
import AboutPage from './old_about';

// new pics
import j1 from '../images/14.jpg';
import sing from '../images/sing.jpg';
import roar from '../images/the_silence_roar.jpg';
import lykke from '../images/lykke.jpg';

import ParagraphSection from '../components/paragraphSection';

const IndexPage = () => {
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const sectionRef = useRef(null);

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 520;

  useEffect(() => {
    document.addEventListener('touchstart', function () {}, true);
  }, []);

  // useEffect(() => {
  //   if (showAbout || showContact) setFooterDelay(0.17);
  // }, [showAbout, showContact]);

  const desktopImages = [
    roar,
    sing,
    johannaCover,
    johannaBrun51,
    johannaBrun8,
    johannaBrun6New,
    johannaBrun3Wide,
  ];
  const mobileImages = [
    roar,
    sing,
    johannaCover,
    johannaBrun5,
    johannaBrun8,
    johannaBrun2,
    johannaBrun3,
  ];
  const images = isMobile ? mobileImages : desktopImages;

  return (
    <Layout
      showAbout={showAbout}
      setShowAbout={setShowAbout}
      showContact={showContact}
      setShowContact={setShowContact}
    >
      <Helmet
        title={'JOHANNA BRUN | Swedish Artist, Producer & Songwriter'}
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <meta property="og:image" content={johannaCover} />
        <meta name="twitter:card" content={johannaCover} />
        <meta name="twitter:image" content={johannaCover} />
        <meta
          name="description"
          content={
            "Johanna Brun, a versatile artist from Strängnäs, Sweden, is making her mark in Stockholm's music scene. Aside from studies at the Royal College of Music, she's also a passionate composer, producer, and performer. Her journey, rooted in childhood memories of her father's studio, is now blossoming into her debut album, a heartfelt reflection of her experiences and nature's influence."
          }
        />
        <link rel="icon" href={favicon} />
      </Helmet>

      <img
        src={johannaCover}
        alt="Photograph of Johanna Brun, Swedish Artist"
        style={{ display: 'none' }}
      />

      <ImageSlider
        images={images}
        alt={'Johanna Brun'}
        fade={showAbout}
        interval={8000}
        sectionRef={sectionRef}
      />

      <section className="section" ref={sectionRef} style={{ paddingTop: 200 }}>
        <ParagraphTitle
          string={'SOULFUL MELODIES'}
          delay={0}
          fontSize={isMobile ? 43 : 55}
        />
        <br />
        <br />
        <Paragraph
          string={
            "Johanna Brun, an artist from Strängnäs, Sweden, is making her mark in Stockholm's music scene. Aside from studies at the Royal College of Music, she's also a passionate composer, producer, and performer. Her journey, rooted in childhood memories of her father's studio, is now blossoming into her debut album, a heartfelt reflection of her experiences and nature's influence."
          }
          delay={0.5}
          show
          link={{
            string: 'Read more about Johanna',
            to: '/about',
            delay: 1,
          }}
        />
      </section>

      <ParagraphSection
        title="THE SILENCE ROAR"
        description="Johanna's new single 'The Silence Roar' combines Nordic nature-inspired themes with an ambient pop sound to explore courage and inner peace, marking a captivating preview of her upcoming album."
        image={roar}
        link={{
          external: true,
          string: 'Listen now',
          to: 'https://orcd.co/thesilenceroar',
          newTab: true,
        }}
      />

      <ParagraphSection
        reverse
        title="Release event"
        description="Johanna Brun and her band, featuring a debuting string quartet, will showcase her new single 'The Silence Roar' at Lykke Live in Stockholm on April 4th, highlighting her upcoming nature-inspired debut album."
        image={lykke}
        link={{
          external: true,
          string: 'Read more',
          to: 'https://www.lykkenytorget.se/lykke-live/johanna-brun',
          newTab: true,
        }}
      />
      <ParagraphSection
        title="PROPHECY"
        description="'Prophecy', a powerful reflection of Johanna's journey, written during a very challenging time in her life. The song is a testament to her resilience and strength—a prelude to her eagerly awaited debut album."
        image={prophecy}
        link={{
          external: true,
          string: 'Hear the single',
          to: 'https://open.spotify.com/track/0yLGg6EpPHbdGRz5cpmwTV?si=81691e0443e74763',
          newTab: true,
        }}
      />

      {/* <ParagraphSection
        reverse
        title="Listen to Bird"
        description="Johanna Brun's first single 'Bird' from her upcoming debut album, is now available. The song showcases Johanna's unique blend of soulful melodies and introspective lyrics, setting the stage for what promises to be a deeply personal and artistically rich album."
        image={johannaCover}
        link={{
          external: true,
          string: 'Hear the single',
          to: 'https://orcd.co/johannabrun2023',
          newTab: true,
        }}
      /> */}
      {/* 
      <ParagraphSection
        reverse
        title="Release Event"
        description="Join us for an evening as Johanna celebrates the release of her single 'Bird' – the first from her upcoming album. This special event will be held at Hilma in Stockholm on Thursday, December 14th, at 19:00. Experience an emotionally charged performance by Johanna and her talented musicians, featuring live renditions of several new songs."
        image={johannaBrun5Wide}
        link={{
          external: true,
          string: 'Register now',
          to: 'https://johanna-brun-showcase.confetti.events/',
          newTab: true,
        }}
      /> */}

      <ParagraphSection
        reverse
        title="Bird—Live Session"
        description="Hear Johanna's latest single Bird, performed live at the Royal College of Music in Stockholm. The song is a reflection of her childhood, growing up in the countryside of Sweden, and the influence of nature on her music. Bird is the first single from her upcoming debut album."
        image={johannaBrun2}
        link={{
          external: true,
          string: 'Watch on YouTube',
          to: 'https://youtu.be/P1FhfHlnuWk?si=Psq7FkZTOW2CFNh_',
          newTab: true,
        }}
      />

      <ParagraphSection
        title="Press Feature"
        description="Discover the captivating review of Johanna Brun's latest song 'Bird', by David Bentley of the Nordic Music Central. The article explores the emotional journey and musical evolution of the song, highlighting its wonderful blend of strings and Johanna's emotive vocals."
        image={johannaBrun1}
        link={{
          external: true,
          string: 'Read the article',
          to: 'https://www.nordicmusiccentral.com/in-conversation-with-the-remarkable-johanna-brun-sweden/',
          newTab: true,
        }}
      />

      <ParagraphSection
        reverse
        title="Recent Conversation"
        description="Recently featured in an interview by Nordic Music Central, where Johanna shares her journey in the Swedish music scene. The piece delves into her upcoming song release, a prelude to her eagerly awaited debut album, and reflects on talent, perseverance, and overcoming challenges."
        image={johannaBrun3Wide}
        link={{
          external: true,
          string: 'Read the interview',
          to: 'https://www.nordicmusiccentral.com/in-conversation-with-the-remarkable-johanna-brun-sweden/',
          newTab: true,
        }}
      />
    </Layout>
  );
};

export default IndexPage;

export const Head = () => (
  <title>JOHANNA BRUN | Swedish Artist, Producer & Songwriter</title>
);
