import React, { useEffect, useState, useContext } from 'react';
import { gsap, Circ, Power4, Power1, Linear } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { LargeTitle, TextLink } from '../components/text';
import { AudioContext } from '../contexts/AudioContext';

const style = {
  imageWrap: {
    //clipPath: 'inset(100% 0 0 0)',
    opacity: 0,
    overflow: 'hidden',
    margin: 'auto',
    width: '12%',
    height: '6vh',
    marginTop: '65vh',
    marginBottom: '15vh',
    borderRadius: 0,
    position: 'relative',
  },
  image: {
    //transform: 'translateY(100px)',
    transform: 'scale(0.82)',
    borderRadius: 35,
    opacity: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    objectFit: 'cover',
    transition: 'opacity 1.5s ease-in-out',
  },
  titleWrap: {
    position: 'absolute',
    transform: 'scale(0.88)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 25,
    background:
      'linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%)',
  },
  scrollButton: {
    transition: 'opacity 0.4s ease',
    cursor: 'pointer',
    // background: 'rgba(0, 0, 0, 0.1)',
    // backdropFilter: 'blur(20px)',
    marginTop: 40,
    marginBottom: -60,
    width: 38,
    height: 60,
    borderRadius: 50,
    textAlign: 'center',
    opacity: 0,
    transition: 'all 0.4s ease',
  },
};

export default function ImageSlider({
  images,
  alt,
  x,
  y,
  fade,
  interval = 3000,
  sectionRef,
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [ready, setReady] = useState(false);
  const { play } = useContext(AudioContext);

  gsap.registerPlugin(ScrollToPlugin);

  const animateIn = () => {
    gsap.to('.imageWrap', 1.7, {
      opacity: 1,
      ease: Linear.easeOut,
    });
    gsap.to('.imageWrap', 2.2, {
      //clipPath: 'inset(0% 0 0 0)',
      width: window.innerWidth < 520 ? '90vw' : '100%',
      height: window.innerWidth < 520 ? '67vh' : '85vh',
      marginTop: '0vh',
      marginBottom: '0vh',
      borderRadius: 35,
      ease: Circ.easeOut,
    });
    gsap.to('.image', 2.8, {
      //transform: 'translateY(0)',
      transform: 'scale(1.15)',
      ease: Circ.easeOut,
    });
    gsap.to('.largeImageTitle', 0.41, {
      transform: 'scale(1)',
      ease: Linear.easeOut,
    });
    gsap
      .to('.scrollButton', 0.4, {
        opacity: 1,
        ease: Linear.easeOut,
      })
      .delay(2.3);
  };

  const fadeIn = () => {
    gsap.to('.imageWrap', 0.5, {
      opacity: window.innerWidth < 520 ? 0 : 0.12,
      // transform: 'scale(0.89) translateY(0px)',
      width: '80%',
      height: '73vh',
      marginTop: '6vh',
      marginBottom: '6vh',
      ease: Circ.easeOut,
    });
    gsap.to('.largeImageTitle', 0.4, {
      opacity: 0,
      ease: Linear.easeInOut,
    });
  };
  const fadeOut = () => {
    gsap.to('.imageWrap', 0.55, {
      opacity: 1,
      //transform: 'scale(1) translateY(0px)',
      width: '100%',
      height: '85vh',
      marginTop: '0vh',
      marginBottom: '0vh',
      ease: Circ.easeOut,
    });
    gsap.to('.largeImageTitle', 0.6, {
      opacity: 1,
      ease: Linear.easeOut,
    });
  };

  const fadeInOut = (index) => {
    gsap.to(`.image-${index}`, { opacity: 0, duration: 1.5 });
    gsap.to(`.image-${(index + 1) % images.length}`, {
      opacity: 1,
      duration: 1.5,
    });
  };

  useEffect(() => {
    animateIn();
    // set ready to true after animation is done
    setTimeout(() => setReady(true), 3500);
  }, []);

  useEffect(() => {
    if (!ready) return;
    fadeInOut(currentImageIndex);
  }, [ready, currentImageIndex, images.length]);

  useEffect(() => {
    if (!ready) return;
    if (fade) fadeIn();
    else fadeOut();
  }, [fade]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images.length, interval]);

  const scrollToSection = () => {
    if (sectionRef.current) {

      gsap.to(window, {
        scrollTo: { y: sectionRef.current, autoKill: true },
        offsetY: 100,
        duration: 2.3,
        ease: Power1.easeInOut,
      });
    }
  };

  const TITLE_SIZE = 108;

  return (
    <div style={{ ...style.imageWrap }} className="imageWrap">
      <div style={style.titleWrap} className="largeImageTitle">
        <LargeTitle string={'JOHANNA BRUN'} delay={1} fontSize={TITLE_SIZE} />
        <LargeTitle
          string={'ARTIST, PRODUCER'}
          delay={1}
          fontSize={TITLE_SIZE}
        />
        <LargeTitle string={'& SONGWRITER'} delay={1} fontSize={TITLE_SIZE} />
        <div
          style={style.scrollButton}
          className="scrollButton"
          onClick={() => {
            play();
            scrollToSection();
          }}
        >
          <svg
            style={{
              marginTop: 17,
              transform: 'rotate(90deg)',
              pointerEvents: 'none',
            }}
            width="75%"
            height="45%"
            viewBox="0 0 63 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              style={{ pointerEvents: 'none' }}
              d="M46.3028 0.0556641L39.2968 0.675663C39.6688 8.61166 43.7608 14.0057 51.2008 16.2377V16.6717L0.298828 15.5557V22.0037L51.2008 20.8877V21.3217C43.7608 23.5537 39.6688 28.9477 39.2968 36.8837L46.3028 37.5037C46.9228 28.7617 52.3788 22.1277 62.0508 20.2677V17.2917C52.3788 15.4317 46.9228 8.79767 46.3028 0.0556641ZM52.9368 16.6717C52.9368 16.7337 52.9988 16.7337 52.9988 16.7337H52.9368V16.6717ZM52.9368 20.8257H52.9988C52.9988 20.8257 52.9368 20.8257 52.9368 20.8877V20.8257Z"
              fill="#fcfbed"
            ></path>
          </svg>
        </div>
      </div>
      {images.map((src, index) => (
        <img
          key={src}
          style={{
            ...style.image,
            opacity: index === currentImageIndex ? 1 : 0,
          }}
          className={`image image-${index}`}
          src={src}
          alt={alt}
          draggable="false"
        />
      ))}
    </div>
  );
}
